@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/earlyaccess/notosanstc.css');
*{
	font-family: Montserrat, 'Noto Sans TC';	
}

.Chi{
	font-family:'Noto Sans TC';
	font-weight:400;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.Dx:hover{
	background-color: #FFF;		
	filter: drop-shadow(1px 1px 3px #808080);	
	cursor:pointer;
}

.bottom-title{
	font-size:16px;
	font-weight:bold;	
}

.bar1{
	margin-left:10px;
	margin-right:10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor{
	cursor:pointer;
}

.leftright{
	margin-left:10px;
	margin-right:10px;
}

.tag1{
	padding-left:20px;
	padding-right:20px;
	border:1px solid white;
	background-color:teal;
	border-radius:20px;
	color:white;
}

.inputfield1{
	
	border:0px solid black !important;
	font-size:15px !important;
	background-color:white !important;			
	justify-content:flex-end;
	
}

.form-control{
	font-size:14px !important;
}

.f-s-b{
	display:flex;
	flex-direction:row;
	justify-content:space-between;
}

@media (min-width: 1000px){ /*desktop*/
	.Dx{
		width:25%;
	}
	

	.res-front-3{
		width:30%;
	}
	
	.mobile-div{
		display:none;
	}
	.inputfield1{
		width:200px;
	}
	.mm1{
		flex-direction:row;		
	}
	.mm2{
		width:58%;
	}
	.mm3{
		width:40%;
	}
	.mm4{
		width:20%;
	}
}

@media (max-width: 1000px){  /*mobile*/
	.Dx{
		width:100%;
	}	
	
	.res-front-3{
		width:100%;
	}
	
	.desktop-div{
		display:none;
	}
	.mm1{
		flex-direction:column;
	}

	.mm2{
		width:100%;
	}
	.mm3{
		width:100%;
	}

	.mm4{
		width:100%;
	}	
	.mm5{
		width:100% !important;
	}	
}

.noselect{
	user-select:none;
}





/* slider  start */
.horizontal-slider {
  width: 100%;
  max-width: 800px;
  height: 20px;
  margin: auto;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 5px solid #ffffff;
  border-radius: 100%;
  display: block;  
}
.example-thumb.active {  
  background-color: white;
  outline:0px solid red !important;  
}
.example-track {
  position: relative;  
  background: #83a9ff;
  
}
.example-track.example-track-1 {
	
    background: #83a9ff;
}
.horizontal-slider .example-track {
  top: 15px;
  height: 4px;
  
}
.slider-border1{
	height: 20px;
	width: 20px;
	border:1px solid white;
	background-color: #FFF;
	border-radius: 50%;
	display: inline-block;
	filter: drop-shadow(1px 1px 5px #808080);
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	
}
.example-thumb:active{	
	
	filter: drop-shadow(1px 1px 5px #E0E0E0); 	
}

/* slider  end */


.alignright{
	text-align:right;
}

.text1{	
	border:0px solid black;
	border-bottom:0px solid transparent;  /*key*/
	color:#808080;	
	font-size:20px;
	font-weight:bold;
	width:100%;
	marginTop:20px;
}

.text1-comma{	
	border:0px solid black;
	border-bottom:0px solid transparent;  /*key*/
	color:#808080;	
	font-size:20px;
	font-weight:bold;
	width:100%;
	marginTop:20px;
}


.border1{
	border:2px solid #E0E0E0;
}
input:focus{
    outline: none;
}

.label1x{	
	padding-left:10px;
	padding-right:10px;	
	font-weight:bold;
	color:#C0C0C0;
	font-size:20px;
	user-select:none;
	margin-top:2px;  /*key*/
}
.field1{
	padding:20px 20px 20px 20px;
	border:1px solid #C0C0C0;
	margin-top:0px;
}
.label1x-a{
	margin-top:0px;
	padding-left:10px;
	padding-right:10px;
	font-weight:bold;
	color:#0E0E0E ;
	font-size:20px !important;
	user-select:none;
}

input[type='checkbox']{  		
	cursor:pointer;
	width: 15px !important;
	height: 15px !important;
	margin: 0px;	
	margin-left:3px;
	fill: green;
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance:none;
	outline: 2px solid lightblue;
	box-shadow: none;
	font-size: 2em;
	background-color:white;
}

input[type='checkbox']:checked{  	
	background-image:url("https://wpsdhk.com/tick.svg");	
}


table {
	width:100%;	
}

table tr td{
	border:1px solid #C0C0C0;
}




.bar1-style1{
	
	/*transform: translate(0%, 0%);*/
	height:120px;
	background-color:rgba(30,129,176,0);
	transition-property:height background-color;
	transition-timing-function:ease;
	transition-duration:0.6s;
	color:white;
}

.bar1-style2{	
	height:60px;
	background-color:rgba(30,129,176,1);
	transition-property:height background-color;
	transition-timing-function:ease;
	transition-duration:0.6s;
	color:white;
	filter: drop-shadow(1px 1px 10px #808080);	
}


.curtain-style1{
	transition-property:height;
	transition-timing-function:ease;
	transition-duration:0.6s;
	height:50%;
}

.curtain-style2{
	transition-property:height;
	transition-timing-function:ease;
	transition-duration:0.6s;
	height:0%;
}



.curtain-style3{
	transition-property:height top;
	transition-timing-function:ease;
	transition-duration:0.6s;
	height:0%;
	top:100%;
}

.curtain-style4{
	transition-property:height top;
	transition-timing-function:ease;
	transition-duration:0.6s;
	height:50%;
	top:50%;
}

.opac1{	
	transition-property:opacity;
	transition-timing-function:ease;
	transition-duration:0.6s;
	opacity:0;
}

.go-up{
	transform: translateY(28px);
	transition-property:transform;
	transition-timing-function:ease;
	transition-duration:0.6s;
}

.go-down{
	transform: translateY(-28px);
	transition-property:all;
	transition-timing-function:ease;
	transition-duration:0.6s;
}

.go-up-down{
	transform: translateY(0px);
	transition-property:all;
	transition-timing-function:ease;
	transition-duration:0.6s;
}

.opac2{
	transition-property:opacity margin;
	transition-timing-function:ease;
	transition-duration:0.6s;
	opacity:1;	
}

.server-background-image {
  width: 100px;
  height: 100px;
  cursor:pointer;
  background-image:url("https://wpsdhk.com/image/server_youshi.png");	
  background-repeat: no-repeat;
}

.server-background-image2 {
  width: 60px;
  height: 60px;
  cursor:pointer;
  background-image:url("https://wpsdhk.com/image/server_youshi.png");	
  background-repeat: no-repeat;
  
}


.server-background-image-0{
  background-position: 0px 0px;
}
  
.server-background-image-0:hover{	
	background-position: -100px 0px;
}

.server-background-image-1{
  background-position: 0px -100px;
}
  
.server-background-image-1:hover{	
	background-position: -100px -100px;
}

.server-background-image-2{
  background-position: 0px -200px;
}
  
.server-background-image-2:hover{	
	background-position: -100px -200px;
}

.server-background-image-3{
  background-position: 0px -300px;
}
  
.server-background-image-3:hover{	
	background-position: -100px -300px;
}



.server-background-image-4{
  background-position: 0px -400px;
}
  
.server-background-image-4:hover{	
	background-position: -100px -400px;
}



.server-background-image-5{
  background-position: 0px -500px;
}
  
.server-background-image-5:hover{	
	background-position: -100px -500px;
}

.server-background-image-6{
  background-position: 0px -600px;
}
  
.server-background-image-6:hover{	
	background-position: -100px -600px;
}

.server-background-image-7{
  background-position: 0px -700px;
}
  
.server-background-image-7:hover{	
	background-position: -100px -700px;
}


.grid1{
	margin-right:15px;
	border:1px solid #D0D0D0;
	padding:10px 10px 10px 10px;
	display:flex;
	min-width:90px;
	flex-direction:column;
	align-items:center;
}

.grid2{
	font-size:10px;
	
}

.ip-img{
	height:100px;
	margin-bottom:50px;
}

.ip1-capt{
	margin-top:60px;
	margin-bottom:60px;
	font-size:30px;
	font-weight:bold;
}

.mobile-menu-show{
	height:35px;
	color:white;
	font-size:20px;
	overflow:hidden;
	padding-left:20px;
	border-top:1px solid #FFFFFF;
	transition-property:height;
	transition-timing-function:ease;
	transition-duration:0.3s;
}

.mobile-menu-hide{	
	color:white;
	height:0px;
	overflow:hidden;
	font-size:20px;
	padding-left:20px;
	border-top:0px solid #FFFFFF;
	transition-property:height;
	transition-timing-function:ease;
	transition-duration:0.3s;
}


.mobile-div8{
	width:100%;
	background-color:black;
	padding:10px 10px 10px 10px;
	border:0px solid red;
}

.mobile-div8 > :nth-child(1){
	margin-bottom:10px;
	font-weight:bold;
	font-size:30px;
	color:white;
	width:100%;
	text-align:center;
}

.mobile-div8 > :nth-child(2){
	margin-bottom:20px;
	color:white;
	margin-left:20px;
	font-size:20px;
}


.in-viewport{
	margin-top: -0px;	
	transition-property:margin-top;
	transition-timing-function:ease;
	transition-duration:0.5s;
}

.out-viewport{	
	margin-top: 50px;
	transition-property:margin-top;
	transition-timing-function:ease;
	transition-duration:0.5s;
}